import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.locale('es')
dayjs.extend(isBetween)

interface DateRangeConfig {
  key: string
  label: string
  getDates: () => [Dayjs, Dayjs]
}

export const DATE_RANGES: Record<string, DateRangeConfig> = {
  today: {
    key: 'today',
    label: 'Hoy',
    getDates: () => {
      const today = dayjs()
      return [today, today]
    },
  },
  lastWeek: {
    key: 'lastWeek',
    label: 'Última semana',
    getDates: () => [dayjs().subtract(1, 'week'), dayjs()],
  },
  lastMonth: {
    key: 'lastMonth',
    label: 'Último mes',
    getDates: () => [dayjs().subtract(1, 'month'), dayjs()],
  },
  last3Months: {
    key: 'last3Months',
    label: 'Últimos 3 meses',
    getDates: () => [dayjs().subtract(3, 'month'), dayjs()],
  },
  lastYear: {
    key: 'lastYear',
    label: 'Último año',
    getDates: () => [dayjs().subtract(1, 'year'), dayjs()],
  },
} as const

// Type for the date range keys
export type DateRangeKey = keyof typeof DATE_RANGES

export const getDateRange = (key: DateRangeKey): [Dayjs, Dayjs] => {
  return DATE_RANGES[key].getDates()
}

export const formatDateRange = (range: [Dayjs | null, Dayjs | null]): string => {
  if (!range[0] || !range[1]) return 'Seleccionar...'

  for (const config of Object.values(DATE_RANGES)) {
    const [presetStart] = config.getDates()
    if (range[0].isSame(presetStart, 'day')) {
      return config.label
    }
  }

  return `${range[0].format('DD/MM/YYYY')} - ${range[1].format('DD/MM/YYYY')}`
}

export const getMenuItems = () =>
  Object.values(DATE_RANGES).map(({ key, label }) => ({
    key,
    label,
  }))
