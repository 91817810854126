import React from 'react'
import dayjs from 'dayjs'
import { Button, Col, Pagination, Row, Space, Typography } from 'antd'
import { Plus } from 'lucide-react'

import DashboardNavbar from '../components/dashboard/navbar'

import { ContentSelector } from '../components/dashboard/content-selector/content-selector'
import { fullData } from '../mocks/full-data'

import { VideoData, PerformanceData } from '../../types/video'
import { useDashboardContext } from '../context/dashboard'
import MetricsContainer from '../components/dashboard/metrics/metrics-container'

const { Title } = Typography
interface DashboardPageProps {
  userInfo?: any
  onNavigateToContent: () => void
}

export const DashboardPage: React.FC<DashboardPageProps> = ({ userInfo, onNavigateToContent }) => {
  const { filteredVideos, setAllVideos, sortConfig } = useDashboardContext()
  const [selectedVideos, setSelectedVideos] = React.useState<VideoData[]>([])
  const [currentPage, setCurrentPage] = React.useState(1)
  const itemsPerPage = 10

  const performanceData: PerformanceData = {
    date: '',
    video: '',
    preview: '',
    thumbnail: '',
    caption: 'Performance Summary',
    impressions: filteredVideos.reduce((sum, video) => sum + video.impressions, 0),
    video_views: filteredVideos.reduce((sum, video) => sum + video.video_views, 0),
    cta_click: filteredVideos.reduce((sum, video) => sum + video.cta_click, 0),
    isPerformance: true,
  }

  // gestiona la selección y deselección de videos del selector de contenidos
  const handleVideoSelection = (video: VideoData) => {
    setSelectedVideos(prevSelected => {
      const isSelected = prevSelected.some(v => v.id === video.id)

      if (isSelected) {
        const newSelected = prevSelected.filter(v => v.id !== video.id)

        console.log('newSelected', newSelected)
        return newSelected.length > 0 ? newSelected : prevSelected // evita dejar vacío si no es deseado
      }

      // seleccionar nuevo video
      const compareValues = (a: VideoData, b: VideoData) => {
        const multiplier = sortConfig.direction === 'asc' ? 1 : -1
        if (sortConfig.type === 'date') {
          return multiplier * (dayjs(a.date).unix() - dayjs(b.date).unix())
        }
        return multiplier * (a.impressions - b.impressions)
      }

      // copia el array actual
      const newSelected = [...prevSelected]
      let i = 0
      while (i < newSelected.length && compareValues(newSelected[i], video) < 0) {
        i++
      }

      newSelected.splice(i, 0, video)

      const newPage = Math.ceil((i + 1) / itemsPerPage)
      setCurrentPage(newPage)
      return newSelected
    })
  }

  const displayedMetrics = React.useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return selectedVideos.slice(startIndex, endIndex).map(video => ({
      ...video,
      impressions: Number(video.impressions),
      video_views: Number(video.video_views),
      cta_click: Number(video.cta_click),
      date: dayjs(video.date, 'YYYYMMDD').format('YYYY-MM-DD'),
    }))
  }, [selectedVideos, currentPage])

  React.useEffect(() => {
    if (fullData.length > 0) {
      const convertedData = fullData.map(video => ({
        ...video,
        id: `${dayjs(video.date, 'YYYYMMDD').format('YYYY-MM-DD')}_${video.video_source}`,
        impressions: parseInt(video.impressions, 10),
        video_views: parseInt(video.video_views, 10),
        cta_click: parseInt(video.cta_click, 10),
        date: dayjs(video.date, 'YYYYMMDD').format('YYYY-MM-DD'),
      }))
      setAllVideos(convertedData)
    }
  }, [setAllVideos])

  React.useEffect(() => {
    if (filteredVideos.length > 0) {
      setSelectedVideos(filteredVideos)
    } else {
      setSelectedVideos([])
    }
  }, [filteredVideos])

  return (
    <Space
      direction='vertical'
      size='large'
      style={{
        width: '100%',
        padding: '16px',
        paddingLeft: '0px',
      }}
    >
      {/* File #1: Header*/}
      <Row justify='space-between' align='middle'>
        <Col>
          <Title style={{ fontWeight: 500 }}>
            Hola, <span style={{ fontWeight: 700 }}>{userInfo?.['custom:clientId']}</span>
          </Title>
        </Col>
        <Col>
          <Button
            style={{
              width: '200px',
              height: '45px',
              fontSize: '14px',
              fontWeight: 600,
              color: 'rgba(255, 255, 255, 1)',
            }}
            icon={<Plus width={15} />}
            onClick={onNavigateToContent}
          >
            Cargar Contenido
          </Button>
        </Col>
      </Row>

      {/* File #2: Navbar */}
      <DashboardNavbar />

      <MetricsContainer videoData={performanceData} />

      {/* File #3: Content */}
      <Row gutter={16}>
        <Col lg={5} style={{ justifyContent: 'center' }}>
          <ContentSelector
            videoData={filteredVideos}
            onVideoSelect={handleVideoSelection}
            selectedVideos={selectedVideos}
          />
        </Col>
        <Col lg={19}>
          {displayedMetrics.map(videoData => (
            <MetricsContainer key={videoData.id} videoData={videoData} />
          ))}
          <Pagination
            current={currentPage}
            total={selectedVideos.length}
            pageSize={itemsPerPage}
            onChange={setCurrentPage}
            style={{ marginTop: '16px', textAlign: 'right' }}
          />
        </Col>
      </Row>
    </Space>
  )
}
export default DashboardPage
