import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { SortState } from '../../types/sort'
import { VideoData } from '../../types/video'

const DEFAULT_DATE_RANGE: [Dayjs, Dayjs] = [dayjs().subtract(1, 'week'), dayjs()]

interface DashboardContextType {
  activeDateRange: [Dayjs | null, Dayjs | null]
  setDateRange: (range: [Dayjs | null, Dayjs | null], showMessage?: boolean) => void
  filteredVideos: VideoData[]
  setAllVideos: (videos: VideoData[]) => void
  sortConfig: SortState
  setSortConfig: React.Dispatch<React.SetStateAction<SortState>>
}

const DashboardContext = React.createContext<DashboardContextType | undefined>(undefined)

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeDateRange, setActiveDateRange] =
    React.useState<[Dayjs | null, Dayjs | null]>(DEFAULT_DATE_RANGE)
  const [allVideos, setAllVideos] = React.useState<VideoData[]>([])
  const [sortConfig, setSortConfig] = React.useState<SortState>({
    type: 'impressions',
    direction: 'desc',
  })

  const getFilteredVideos = React.useCallback(
    (range: [Dayjs | null, Dayjs | null]) => {
      const [start, end] = range

      if (!start || !end) {
        return allVideos.filter(video => dayjs(video.date).isAfter(DEFAULT_DATE_RANGE[0], 'day'))
      }

      return allVideos.filter(video => {
        const videoDate = dayjs(video.date)
        return videoDate.isBetween(start, end, 'day', '[]')
      })
    },
    [allVideos],
  )

  // Update the active date range. The range is maintained even when there are no videos
  const setDateRange = React.useCallback((newRange: [Dayjs | null, Dayjs | null]) => {
    setActiveDateRange(newRange)
  }, [])

  const filteredVideos = React.useMemo(() => {
    let videos = getFilteredVideos(activeDateRange)

    return [...videos].sort((a, b) => {
      const multiplier = sortConfig.direction === 'asc' ? 1 : -1

      if (sortConfig.type === 'date') {
        return multiplier * (dayjs(a.date).unix() - dayjs(b.date).unix())
      }

      return multiplier * (a.impressions - b.impressions)
    })
  }, [activeDateRange, getFilteredVideos, sortConfig])

  const value = {
    activeDateRange,
    setDateRange,
    filteredVideos,
    setAllVideos,
    sortConfig,
    setSortConfig,
  }

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}

export const useDashboardContext = () => {
  const context = React.useContext(DashboardContext)
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider')
  }
  return context
}
