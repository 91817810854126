import React from 'react'
import { Col, Dropdown, Space, Row, Typography } from 'antd'
import type { MenuProps } from 'antd'
import { ArrowDownAZ, ArrowUpAZ, Filter, Share } from 'lucide-react'

import { SecondaryButton } from '../ui/secondary-button'
import { DateRangePicker } from './custom-calendar'

import { SortState, SortType } from '../../../types/sort'
import { useDashboardContext } from '../../context/dashboard'

const { Text } = Typography

const SortButton = () => {
  const { sortConfig, setSortConfig } = useDashboardContext()

  const handleMenuClick = (type: SortType) => {
    setSortConfig((prev: SortState) => ({
      type,
      direction: prev.type === type && prev.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const items: MenuProps['items'] = [
    {
      key: 'date',
      label: 'Ordenar por fecha',
      icon:
        sortConfig.type === 'date' ? (
          sortConfig.direction === 'asc' ? (
            <ArrowUpAZ size={16} />
          ) : (
            <ArrowDownAZ size={16} />
          )
        ) : null,
      onClick: () => handleMenuClick('date'),
    },
    {
      key: 'impressions',
      label: 'Ordenar por impresiones',
      icon:
        sortConfig.type === 'impressions' ? (
          sortConfig.direction === 'asc' ? (
            <ArrowUpAZ size={16} />
          ) : (
            <ArrowDownAZ size={16} />
          )
        ) : null,
      onClick: () => handleMenuClick('impressions'),
    },
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <SecondaryButton
        icon={<Filter width={12} />}
        style={{ borderColor: 'rgba(138, 148, 166, 1)' }}
      >
        {`Ordenar por ${sortConfig.type === 'date' ? 'fecha' : 'impresiones'} ${
          sortConfig.direction === 'asc' ? '↑' : '↓'
        }`}
      </SecondaryButton>
    </Dropdown>
  )
}

const DashboardNavbar: React.FC = () => {
  return (
    <Row justify='space-between' align='middle'>
      <Col>
        <Text style={{ fontSize: '16px', fontWeight: 600 }}>Tus Contenidos</Text>
      </Col>
      <Col>
        <Space size='large'>
          <SortButton />
          <DateRangePicker />
          <SecondaryButton
            icon={<Share width={12} />}
            style={{ borderColor: 'rgba(138, 148, 166, 1)' }}
          >
            Compartir
          </SecondaryButton>
        </Space>
      </Col>
    </Row>
  )
}

export default DashboardNavbar
