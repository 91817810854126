import React from 'react'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import {
  Button,
  Flex,
  FloatButton,
  Form,
  DatePicker,
  Row,
  Col,
  Typography,
  message,
  Tooltip,
} from 'antd'
import { CalendarOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'

import {
  arrayMove,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'

import { InputState } from '../../types/form'
import { useFetchCarouselDates } from '../hooks/carousel/useFetchCarouselDates'
import { useFetchCarouselData } from '../hooks/carousel/useFetchCarouselData'
import { useSaveCarouselContent } from '../hooks/carousel/useSaveCarouselContent'
import { MAX_CARDS } from '../constants'
import { SortableCard } from '../components/content/sortable-card'
import { validateUrl } from '../utils/url-validator'
import { HelpModal } from '../components/content/help-modal'
import { tokens } from '../theme/tokens'
import { SecondaryButton } from '../components/ui/secondary-button'
import { Plus } from 'lucide-react'

const today = dayjs()

const INITIAL_REQUIRED_CARDS = 4

const submittedDateStyle = {
  color: '#FFFFFF',
  backgroundColor: tokens.colors.supporting.green[500],
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '7px',
  fontWeight: 'bold',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
}

interface SuccessState {
  [date: string]: {
    [id: string]: boolean
  }
}

const initialInputState: InputState = {
  id: '',
  order: 0,
  url: '',
  cta: '',
  title: '',
  showPlaceholder: true,
  isValid: false,
  ctaUpdated: false,
  titleUpdated: false,
}

const generateInitialInputs = (length: number): InputState[] =>
  Array.from({ length }, (_, index) => ({
    ...initialInputState,
    id: uuidv4(),
    order: index + 1,
  }))

export const EditFormPage: React.FC<{ widgetId: string }> = ({ widgetId }) => {
  const [form] = Form.useForm()
  const date = Form.useWatch('date', form)
  const currentDate = date?.format('YYYY-MM-DD') || today.format('YYYY-MM-DD')

  // API hooks
  const { fetchSubmittedDates, submittedDates } = useFetchCarouselDates({ widgetId })
  const { fetchCarouselData } = useFetchCarouselData({ widgetId })
  const { saveCarouselContent } = useSaveCarouselContent({ widgetId })
  const [inputs, setInputs] = React.useState<InputState[]>(() =>
    generateInitialInputs(INITIAL_REQUIRED_CARDS),
  )
  const [isFormComplete, setIsFormComplete] = React.useState(false)
  const [isModified, setIsModified] = React.useState(false)
  const [sentSuccessByDate, setSentSuccessByDate] = React.useState<SuccessState>({})
  const [isHelpModalOpen, setHelpModalOpen] = React.useState<boolean>(false)
  const [initialInputs, setInitialInputs] = React.useState<InputState[]>([])

  const checkFormCompletion = React.useCallback(() => {
    const allFieldsComplete = inputs.every(
      input => input.url.trim() !== '' && input.cta.trim() !== '' && input.title.trim() !== '',
    )
    setIsFormComplete(allFieldsComplete && !!date)
  }, [inputs, date])

  const [addedCards, setAddedCards] = React.useState<Set<string>>(new Set()) // Tarjetas nuevas agregadas
  

  const handleAddCard = () => {
    const newCardId = uuidv4()
    setInputs(prev => [
      ...prev,
      {
        ...initialInputState,
        id: newCardId,
        order: prev.length + 1,
      },
    ])
    setIsModified(true)
  }

  const handleDeleteCard = (id: string) => {
    setInputs(prev => {
      const newInputs = prev.filter(input => input.id !== id)
  
      setIsModified(isFormChanged())
      return newInputs.map((input, index) => ({
        ...input,
        order: index + 1,
      }))
    })
  }



  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  // Function to handle the drag and drop of the formCards
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
  
    if (active.id !== over?.id) {
      setInputs(inputs => {
        const oldIndex = inputs.findIndex(input => input.id === active.id)
        const newIndex = inputs.findIndex(input => input.id === over?.id)
        const reorderedInputs = arrayMove(inputs, oldIndex, newIndex)
  
        setIsModified(isFormChanged())
        return reorderedInputs.map((input, index) => ({
          ...input,
          order: index + 1,
        }))
      })
    }
  }

  // Function to fetch the carousel data for the selected date
  const fetchCarouselContentByDate = React.useCallback(
    async (selectedDate: dayjs.Dayjs) => {
      try {
        const content = await fetchCarouselData(selectedDate)
  
        const formValues: Record<string, string> = {}
  
        const updatedInputs = content.map(carouselItem => {
          const input = {
            id: carouselItem.id || uuidv4(),
            order: carouselItem.order,
            url: carouselItem.link || '',
            cta: carouselItem.cta || '',
            title: carouselItem.title || '',
            showPlaceholder: !carouselItem.link,
            isValid: !!carouselItem.link,
            ctaUpdated: !!carouselItem.cta,
            titleUpdated: !!carouselItem.title,
          }
  
          formValues[`link${input.id}`] = input.url
          formValues[`cta${input.id}`] = input.cta
          formValues[`title${input.id}`] = input.title
  
          return input
        })
  
        if (updatedInputs.length === 0) {
          updatedInputs.push(...generateInitialInputs(INITIAL_REQUIRED_CARDS))
        }
  
        setInputs(updatedInputs)
        setInitialInputs(updatedInputs) // Guardar el formulario inicial como referencia
  
        setSentSuccessByDate(prev => ({
          ...prev,
          [selectedDate.format('YYYY-MM-DD')]: updatedInputs.reduce(
            (acc, input) => {
              acc[input.id] = input.isValid
              return acc
            },
            {} as Record<string, boolean>,
          ),
        }))
  
        form.setFieldsValue(formValues)
        setIsModified(false)
      } catch (err) {
        if (err instanceof Error) {
          const errorMessage = err.message || ''
          if (errorMessage.includes('404')) {
            const emptyInputs = generateInitialInputs(MAX_CARDS)
            setInputs(emptyInputs)
            setInitialInputs(emptyInputs) // Guardar estado vacío como referencia inicial
            setIsModified(false)
          }
        } else {
          console.error('An unexpected error occurred:', err)
        }
      }
    },
    [form, fetchCarouselData],
  )

  // Función para verificar si el formulario cambió
const isFormChanged = React.useCallback(() => {
  if (inputs.length !== initialInputs.length) return true

  return inputs.some((input, index) => {
    const initialInput = initialInputs[index]
    return (
      input.id !== initialInput.id ||
      input.order !== initialInput.order ||
      input.url.trim() !== initialInput.url.trim() ||
      input.cta.trim() !== initialInput.cta.trim() ||
      input.title.trim() !== initialInput.title.trim()
    )
  })
}, [inputs, initialInputs])

  const handleSubmit = async (values: any) => {
    const date = values.date.format('YYYY-MM-DD')

    const carousel = {
      content: inputs.map(input => ({
        id: input.id,
        order: input.order,
        link: values[`link${input.id}`],
        cta: values[`cta${input.id}`],
        title: values[`title${input.id}`],
      })),
    }

    const success = await saveCarouselContent(date, carousel)
    if (success) {
      message.success('El contenido se ha enviado exitosamente')
      setIsModified(false)

      const newSuccessState = {
        ...sentSuccessByDate,
        [date]: inputs.reduce(
          (acc, input) => {
            acc[input.id] = true
            return acc
          },
          {} as Record<string, boolean>,
        ),
      }

      setSentSuccessByDate(newSuccessState)
      fetchSubmittedDates()

      fetchSubmittedDates()
    } else {
      message.error('Hubo un error al enviar el contenido. Intenta nuevamente')
    }
  }

  const handleInputChange = (id: string, field: 'url' | 'cta' | 'title', value: string) => {
    setInputs(prevInputs => {
      return prevInputs.map(input => {
        if (input.id === id) {
          if (input[field] !== value) setIsModified(true)

          setSentSuccessByDate(prev => ({
            ...prev,
            [currentDate]: {
              ...(prev[currentDate] || {}),
              [id]: false,
            },
          }))

          // Update the input state when the value changes
          const updates: Partial<InputState> = { [field]: value }

          // Update the cta and title fields when the url changes
          if (field === 'url') {
            updates.showPlaceholder = !value
            updates.isValid = validateUrl(value)

            if (!value) {
              updates.title = ''
            }
            updates.cta = value
            updates.title = ''
          } else if (field === 'cta') {
            updates.cta = value
            updates.isValid = validateUrl(value)
            updates.ctaUpdated = true
          } else if (field === 'title') {
            updates.title = value.replace(/\n+/g, ' ')
            updates.titleUpdated = true
          }

          return { ...input, ...updates }
        }
        return input
      })
    })
  }

  // Check if the current date has content submitted
  const isSubmittedDate = (currentDate: dayjs.Dayjs): boolean => {
    if (!currentDate) return false
    return submittedDates.includes(currentDate.format('YYYY-MM-DD'))
  }

  // Effect to initialize the form values with the carousel data
  React.useEffect(() => {
    const formValues: Record<string, string> = {}
    inputs.forEach(input => {
      formValues[`link${input.id}`] = input.url
      formValues[`cta${input.id}`] = input.cta
      formValues[`title${input.id}`] = input.title
    })
    form.setFieldsValue(formValues)
  }, [inputs, form])

  React.useEffect(() => {
    if (date) {
      fetchCarouselContentByDate(date)
    }
  }, [date, fetchCarouselContentByDate])

  // Effect to fetch the submitted calendar dates
  React.useEffect(() => {
    fetchSubmittedDates()
  }, [fetchSubmittedDates])

  React.useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisitedEditForm')
    if (!hasVisited) {
      setHelpModalOpen(true)
      localStorage.setItem('hasVisitedEditForm', 'true')
    }
  }, [])

  React.useEffect(() => {
    checkFormCompletion()
  }, [inputs, checkFormCompletion])

  React.useEffect(() => {
    setIsModified(isFormChanged())
  }, [inputs, isFormChanged])

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Flex
        vertical
        gap='middle'
        style={{ height: '100%', width: '100%', overflowX: 'hidden', paddingTop: '1em' }}
      >
        {/* File #2: Form content */}
        <Row gutter={[16, 16]} justify='start'>
          <Col xs={{ span: 24, offset: 1 }} lg={{ span: 12, offset: 6 }}>
            <Form layout='vertical' form={form} onFinish={handleSubmit} requiredMark={false}>
              <Flex
                justify='space-between'
                style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}
              >
                <div>
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    Carga tus contenidos
                  </Typography.Title>
                </div>

                {/*  Date selector */}
                <div>
                  <Form.Item
                    name='date'
                    rules={[{ type: 'object', required: true, message: 'Seleccionar una fecha' }]}
                    style={{ marginBottom: 0, flexGrow: 1, width: '100%' }}
                    initialValue={today}
                  >
                    <DatePicker
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        padding: '8px 16px',
                      }}
                      className='custom-placeholder'
                      placeholder='Seleccionar fecha'
                      suffixIcon={<CalendarOutlined style={{ color: '#344054' }} />}
                      cellRender={currentDate => {
                        const dayjsDate = dayjs(currentDate)
                        const isDateWithContent = isSubmittedDate(dayjsDate)
                        return (
                          <div style={isDateWithContent ? submittedDateStyle : undefined}>
                            {dayjsDate.date()}
                          </div>
                        )
                      }}
                    />
                  </Form.Item>
                </div>
              </Flex>

              <SortableContext items={inputs.map(input => input.id)} strategy={rectSortingStrategy}>
                {inputs.map((input, index) => (
                  <SortableCard
                    key={input.id}
                    input={input}
                    required={index < INITIAL_REQUIRED_CARDS}
                    isOptional={index >= INITIAL_REQUIRED_CARDS}
                    handleInputChange={handleInputChange}
                    isSuccess={sentSuccessByDate[currentDate]?.[input.id] ?? false}
                    onDelete={handleDeleteCard}
                    totalCards={inputs.length} // Pasar el total de tarjetas
                    onAddCard={handleAddCard}  // Pasar la función para agregar tarjeta
                  />
                ))}
              </SortableContext>
              <Form.Item>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <SecondaryButton onClick={handleAddCard} style={{ width: '100%' }} icon={<Plus width={15} />}>
                    Agregar tarjeta
                  </SecondaryButton>
                  <div
                    style={{
                      position: 'fixed',
                      top: '80%', // Baja la posición del botón
                      right: '15%', // Mueve el botón más hacia la izquierda
                      transform: 'translateY(-50%)', // Centra verticalmente respecto al top ajustado
                      zIndex: 1000, // Asegura que el botón esté encima de otros elementos
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {!isFormComplete || !isModified ? (
                      <Tooltip
                        title='Recuerda que necesitas completar 4 tarjetas para poder enviar contenido'
                        placement='left'
                      >
                        <Button
                          htmlType='submit'
                          disabled
                          style={{ width: 'auto' }}
                        >
                          Enviar
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        htmlType='submit'
                        disabled={!isFormComplete || !isModified}
                        style={{ width: 'auto' }}
                      >
                        Enviar
                      </Button>
                    )}
                  </div>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Flex>
      {/* Help button */}
      <FloatButton
        icon={<QuestionCircleOutlined />}
        style={{ right: 50, bottom: 24 }}
        onClick={() => setHelpModalOpen(true)}
      />
      {/* Modal */}
      <HelpModal open={isHelpModalOpen} onClose={() => setHelpModalOpen(false)} />
    </DndContext>
  )
}
