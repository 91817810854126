import React, { CSSProperties } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Tooltip, Modal, Button } from 'antd'

import { CardContent } from './card-content'
import { CardProps } from '../../../types/card'
import { SecondaryButton } from '../ui/secondary-button'
import { Menu, Trash } from 'lucide-react'

const MIN_CARDS = 4 // Minimum number of cards to maintain

export const SortableCard: React.FC<
  CardProps & { onDelete?: (id: string) => void; isOptional: boolean; onAddCard?: () => void; totalCards: number }
> = ({ input, required, handleInputChange, isSuccess, onDelete, isOptional, onAddCard, totalCards }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: input.id,
    disabled: false,
  })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 'auto',
    position: 'relative',
  }

  const [isModalVisible, setModalVisible] = React.useState(false)

  const handleDeleteClick = () => {
    setModalVisible(true)
  }

  const handleConfirmDelete = () => {
    setModalVisible(false)
    if (onDelete) {
      onDelete(input.id)
      // Check if a new card needs to be added
      if (totalCards - 1 < MIN_CARDS && onAddCard) {
        onAddCard()
      }
    }
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  return (
    <div ref={setNodeRef} style={style}>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        {/* Drag handle */}
        <div
          {...attributes}
          {...listeners}
          style={{
            cursor: 'move',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: 'auto',
            height: '150.3px',
            backgroundColor: '#EAECF5',
            borderTopLeftRadius: '35px',
            borderBottomLeftRadius: '35px',
            marginBottom: '15px',
            padding: '7px',
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#363F72',
            }}
          >
            {` ${input.order}${required ? '*' : ''}`}
          </span>
          <Menu style={{ color: '#363F72' }} width={14} />
        </div>

        {/* Card content */}
        <div style={{ flex: 1 }}>
          <CardContent
            input={input}
            required={required}
            handleInputChange={handleInputChange}
            isSuccess={isSuccess}
          />
        </div>

        {/* Delete button for optional cards */}
        <>
          <Tooltip title='Eliminar Tarjeta'>
            <button
              type='button'
              onClick={handleDeleteClick}
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                position: 'absolute',
                justifyContent: 'center',
                right: '5px',
                top: '10px',
              }}
            >
              <Trash style={{ color: 'red' }} width={14} />
            </button>
          </Tooltip>
          <Modal
            title='Confirmar Eliminación'
            open={isModalVisible}
            onCancel={handleCancel}
            footer={[
              <Button onClick={handleCancel}>Cancelar</Button>,
              <SecondaryButton key='cancel' onClick={handleConfirmDelete}>
                Eliminar
              </SecondaryButton>,
            ]}
          >
            <p>¿Estás seguro de que deseas eliminar esta tarjeta?</p>
          </Modal>
        </>
      </div>
    </div>
  )
}
