import React from 'react'
import { ConfigProvider, Space } from 'antd'
import { motion, AnimatePresence } from 'framer-motion'

import { tokens } from '../../../theme/tokens'

import { VideoData, PerformanceData } from '../../../../types/video'

import VideoImpression from './video-impression'
import SuccessfulViews from './successful-views'
import VideoClicks from './video-clicks'
import VideoCard from '../video-card'

interface MetricsContainerProps {
  videoData: VideoData | PerformanceData
  animationKey?: string
}

const MetricsContent: React.FC<MetricsContainerProps> = ({ videoData }) => {
  const isPerformance = 'isPerformance' in videoData

  const containerStyles = {
    backgroundColor: isPerformance ? '#ffffff' : '#F6F7F9',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: isPerformance ? 0 : '10px',
    paddingRight: isPerformance ? 0 : '10px',
    marginBottom: '13px',
    borderRadius: '16px',
    gap: isPerformance ? '0' : '15px',
    width: '100%',
    height: isPerformance ? '145px' : 'auto',
    display: 'flex',
    justifyContent: isPerformance ? 'space-between' : 'space-around',
    flexGrow: 1,
  }

  const metrics = (
    <>
      <VideoImpression impressions={videoData.impressions} isPerformance={isPerformance} />
      <SuccessfulViews
        impressions={videoData.impressions}
        video_views={videoData.video_views}
        isPerformance={isPerformance}
      />
      <VideoClicks
        impressions={videoData.impressions}
        cta_click={videoData.cta_click}
        isPerformance={isPerformance}
      />
    </>
  )

  return (
    <Space direction='horizontal' size='large' style={containerStyles}>
      {!isPerformance && (
        <VideoCard
          videoData={videoData as VideoData}
          size={{ width: tokens.cardSize.height, height: tokens.cardSize.height }}
          hasTitle={true}
          withTooltip={true}
        />
      )}
      {metrics}
    </Space>
  )
}

const MetricsContainer: React.FC<MetricsContainerProps> = props => {
  const isPerformance = 'isPerformance' in props.videoData

  if (isPerformance) {
    return <MetricsContent {...props} />
  }

  return (
    <ConfigProvider>
      <AnimatePresence mode='sync'>
        <motion.div
          layout
          key={`metric-${(props.videoData as VideoData).video_source}`}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <MetricsContent {...props} />
        </motion.div>
      </AnimatePresence>
    </ConfigProvider>
  )
}

export default MetricsContainer
