import React from 'react'
import { Card, Row, Tooltip, Typography } from 'antd'
import { Eye } from 'lucide-react'

import { CARD_SIZES } from './sizes'
import { typography } from '../../../theme/typography'
import { numberFormatter, getMetricTooltipContent } from '../../../utils/metrics-utils'

const { Text } = Typography
interface VideoImpressionProps {
  impressions: number
  isPerformance?: boolean
}

const VideoImpression: React.FC<VideoImpressionProps> = ({
  impressions,
  isPerformance = false,
}) => {
  const size = isPerformance ? CARD_SIZES.performance : CARD_SIZES.standard
  return (
    <Tooltip
      title={getMetricTooltipContent({
        isPerformance,
        metricType: 'videoImpressions',
        value: impressions,
      })}
      placement='top'
    >
      <Card
        style={{
          ...size,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          padding: '7px',
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Row
          style={{
            width: '100%',
            height: isPerformance ? '30px' : '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Eye style={{ width: '14px' }} />
        </Row>
        <Row
          style={{
            width: '100%',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: isPerformance
                ? typography.heading.xl.fontSize
                : typography.heading.lg.fontSize,
              fontWeight: 600,
            }}
          >
            {numberFormatter(impressions)}
          </Text>
        </Row>
        <Row
          style={{
            width: '100%',
            height: isPerformance ? '30px' : '20px',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Text
            style={{
              fontSize: isPerformance ? typography.body.md.fontSize : typography.body.sm.fontSize,
            }}
          >
            {isPerformance ? 'Total impresiones' : 'Impresiones del video'}
          </Text>
        </Row>
      </Card>
    </Tooltip>
  )
}

export default VideoImpression
