import React from 'react'
import { Card, Col, Row, Tag, Tooltip, Typography } from 'antd'
import { arc } from 'd3-shape'
import { scaleLinear } from 'd3-scale'
import { Play } from 'lucide-react'

import { CARD_SIZES } from './sizes'
import { tokens } from '../../../theme/tokens'
import { typography } from '../../../theme/typography'
import {
  getPerformanceColor,
  getCurrentLabel,
  getMetricTooltipContent,
} from '../../../utils/metrics-utils'

const { Text } = Typography

const ARC_THICKNESS = 0.75
const OUTER_RADIUS = 1

interface SuccessfulViewsProps {
  impressions: number
  video_views: number
  isPerformance?: boolean
}

const getCoordsOnArc = (angle: number, offset: number = 10) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
]

const SuccessfulViews: React.FC<SuccessfulViewsProps> = ({
  impressions,
  video_views,
  isPerformance = false,
}) => {
  const GAUGE_SIZE = isPerformance ? 9 : 7
  const validTotalViews = impressions || 1
  const percent = video_views / validTotalViews
  const color = getPerformanceColor(percent)
  const size = isPerformance ? CARD_SIZES.performance : CARD_SIZES.standard

  const angleScale = scaleLinear()
    .domain([0, 1])
    //.domain([-0.04, 1.04])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true)

  const angle = angleScale(percent)

  const markerLocation = getCoordsOnArc(angle, 1 - (1 - ARC_THICKNESS) / 2)

  // sections of the arc
  const sections = [
    {
      color: tokens.colors.supporting.red[500],
      startAngle: -Math.PI / 2,
      endAngle: -Math.PI / 6,
    },
    {
      color: tokens.colors.supporting.yellow[500],
      startAngle: -Math.PI / 6,
      endAngle: Math.PI / 6,
    },
    {
      color: tokens.colors.supporting.green[500],
      startAngle: Math.PI / 6,
      endAngle: Math.PI / 2,
    },
  ]

  // parametric arc generator
  const arcGenerator = arc<{
    innerRadius: number
    outerRadius: number
    startAngle: number
    endAngle: number
  }>()
    .innerRadius(ARC_THICKNESS)
    .outerRadius(OUTER_RADIUS)

  return (
    <Tooltip
      title={getMetricTooltipContent({
        isPerformance,
        metricType: 'successfulViews',
        value: video_views,
      })}
      placement='top'
    >
      <Card
        style={{
          ...size,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          padding: '7px',
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Row
          style={{
            width: '100%',
            height: isPerformance ? '30px' : '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Col>
            <Play style={{ width: '14px' }} />
          </Col>
          <Col>
            <Tag
              style={{
                width: '105px',
                height: '20px',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                backgroundColor: tokens.colors.primary.white,
                fontSize: typography.label.fontSize,
              }}
            >
              {getCurrentLabel(percent)}
              <span
                style={{
                  display: 'inline-block',
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: color,
                }}
              />
            </Tag>
          </Col>
        </Row>
        <Row
          style={{
            width: '100%',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <svg
              style={{ overflow: 'visible' }}
              width={`${GAUGE_SIZE}em`}
              viewBox={[-1, -1, 2, 1].join(' ')}
            >
              {sections.map(({ color, startAngle, endAngle }, index) => (
                <path
                  key={index}
                  d={
                    arcGenerator({
                      innerRadius: ARC_THICKNESS,
                      outerRadius: OUTER_RADIUS,
                      startAngle: startAngle,
                      endAngle: endAngle,
                    }) || ''
                  }
                  fill={color}
                />
              ))}
              <circle
                cx={markerLocation[0]}
                cy={markerLocation[1]}
                r='0.13'
                stroke={color}
                strokeWidth='0.08'
                fill='white'
              />
            </svg>
            <div
              style={{
                position: 'absolute',
                bottom: '-0.1em',
                fontSize: isPerformance ? '1.5em' : '1em',
                lineHeight: '1em',
                fontWeight: '700',
              }}
            >
              {Math.round(percent * 100)}%
            </div>
          </Col>
        </Row>
        <Row
          style={{
            width: '100%',
            height: isPerformance ? '30px' : '20px',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Text
            style={{
              fontSize: isPerformance ? typography.body.md.fontSize : typography.body.sm.fontSize,
            }}
          >
            {isPerformance ? 'Total visualizaciones exitosas' : 'Visualizaciones exitosas'}
          </Text>
        </Row>
      </Card>
    </Tooltip>
  )
}

export default SuccessfulViews
