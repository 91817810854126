import React from 'react'
import { Card, Col, Row, Tag, Tooltip, Typography } from 'antd'
import { MousePointerClick } from 'lucide-react'

import { CARD_SIZES } from './sizes'
import { tokens } from '../../../theme/tokens'
import { typography } from '../../../theme/typography'
import {
  getPerformanceColor,
  getCurrentLabel,
  numberFormatter,
  getMetricTooltipContent,
} from '../../../utils/metrics-utils'

const { Text } = Typography
interface VideoClicksProps {
  impressions: number
  cta_click: number
  isPerformance?: boolean
}

const VideoClicks: React.FC<VideoClicksProps> = ({
  impressions,
  cta_click,
  isPerformance = false,
}) => {
  const validTotalViews = impressions || 1
  const percent = cta_click / validTotalViews
  const color = getPerformanceColor(percent)
  const size = isPerformance ? CARD_SIZES.performance : CARD_SIZES.standard

  return (
    <Tooltip
      title={getMetricTooltipContent({
        isPerformance,
        metricType: 'videoClicks',
        value: cta_click,
      })}
      placement='top'
    >
      <Card
        style={{
          ...size,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          padding: '7px',
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Row
          style={{
            width: '100%',
            height: isPerformance ? '30px' : '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Col>
            <MousePointerClick style={{ width: '14px' }} />
          </Col>
          <Tag
            style={{
              width: '105px',
              height: '20px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              backgroundColor: tokens.colors.primary.white,
              fontSize: typography.label.fontSize,
            }}
          >
            {getCurrentLabel(percent)}
            <span
              style={{
                display: 'inline-block',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: color,
              }}
            />
          </Tag>
        </Row>
        <Row
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginTop: '10px',
          }}
        >
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Text
              style={{
                fontSize: isPerformance
                  ? typography.heading.xl.fontSize
                  : typography.heading.lg.fontSize,
                fontWeight: 600,
              }}
            >
              {numberFormatter(cta_click)}
            </Text>
            <Text
              style={{
                fontSize: isPerformance ? typography.body.md.fontSize : typography.body.sm.fontSize,
              }}
            >
              {isPerformance ? 'Total clicks' : 'Clicks del video'}
            </Text>
          </Col>

          <Col
            style={{
              backgroundColor: tokens.colors.primary.neutral[50],
              borderRadius: '8px',
              width: '80px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingLeft: '8px',
              marginTop: '4px',
              marginRight: '10px',
            }}
          >
            <Text
              style={{
                fontSize: isPerformance
                  ? typography.heading.xl.fontSize
                  : typography.heading.lg.fontSize,
                fontWeight: 600,
                lineHeight: typography.heading.lg.lineHeight,
              }}
            >
              {Math.round(percent * 100)}%
            </Text>
            <Text
              style={{
                fontSize: isPerformance ? typography.body.md.fontSize : typography.body.sm.fontSize,
                color: '#5E5E5E',
                fontWeight: 600,
              }}
            >
              CTR
            </Text>
          </Col>
        </Row>
      </Card>
    </Tooltip>
  )
}

export default VideoClicks
