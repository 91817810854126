import { tokens } from '../theme/tokens'

export const getPerformanceColor = (percent: number) => {
  if (percent <= 1 / 3) return tokens.colors.supporting.red[500]
  if (percent <= 2 / 3) return tokens.colors.supporting.yellow[500]
  return tokens.colors.supporting.green[500]
}

export const getCurrentLabel = (percent: number) => {
  return percent <= 1 / 3 ? 'Low performer' : percent <= 2 / 3 ? 'Mid performer' : 'Top performer'
}

export const numberFormatter = (views: number) => {
  if (views < 1000) return views

  const viewsInK = views / 1000
  const viewsInM = views / 1000000
  return viewsInM >= 1 ? `${Math.ceil(viewsInM * 10) / 10}M` : `${Math.ceil(viewsInK * 10) / 10}K`
}

export type MetricType = 'videoImpressions' | 'successfulViews' | 'videoClicks'

interface TooltipParams {
  isPerformance: boolean
  metricType: MetricType
  value: number
}

export const getMetricTooltipContent = ({
  isPerformance,
  metricType,
  value,
}: TooltipParams): string => {
  const metricMessages = {
    videoImpressions: {
      performance: `Alcance total de tus contenidos en el periodo seleccionad: ${value.toLocaleString()}`,
      standard: `Alcance del video: ${value.toLocaleString()}`,
    },
    successfulViews: {
      performance: `Cantidad de vistas exitosas en el periodo seleccionado: ${value.toLocaleString()}`,
      standard: `El video se reprodujo por completo ${value.toLocaleString()} ${value === 1 ? 'vez' : 'veces'}`,
    },
    videoClicks: {
      performance: `Número de clicks en los videos del periodo seleccionado: ${value.toLocaleString()}`,
      standard: `${value.toLocaleString()} clicks en el video`,
    },
  }

  return isPerformance
    ? metricMessages[metricType].performance
    : metricMessages[metricType].standard
}
