import { tokens } from '../../../theme/tokens'

export const CARD_SIZES = {
  standard: {
    width: tokens.cardSize.width,
    height: tokens.cardSize.height,
  },
  performance: {
    width: 330,
    height: 130,
  },
} as const
