import React from 'react'
import { Col, Pagination, Row, Alert } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { VideoData } from '../../../../types/video'
import VideoCard from '../video-card'

interface ImageCarouselProps {
  pageSize?: number
  videoData: VideoData[]
  onVideoSelect: (video: VideoData) => void
  selectedVideos: VideoData[]
}

export const ContentSelector: React.FC<ImageCarouselProps> = ({
  pageSize = 8,
  videoData,
  onVideoSelect,
  selectedVideos,
}) => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [visibleRange, setVisibleRange] = React.useState({ start: 1, end: 3 })

  const totalPages = Math.ceil(videoData.length / pageSize)

  const handlePageChange = (page: number, type?: 'prev' | 'next') => {
    setCurrentPage(page)

    if (page <= 3) {
      setVisibleRange({ start: 1, end: 3 })
      return
    }
    if (type === 'next' && page > visibleRange.end) {
      setVisibleRange(prev => ({
        start: prev.start + 3,
        end: Math.min(prev.end + 3, totalPages),
      }))
    } else if (type === 'prev' && page < visibleRange.start) {
      setVisibleRange(prev => ({
        start: Math.max(prev.start - 3, 1),
        end: prev.end - 3,
      }))
    }
  }

  const startIndex = (currentPage - 1) * pageSize
  const endIndex = startIndex + pageSize
  const currentPageItems = videoData.slice(startIndex, endIndex)

  const paginationStyle = `
    .ant-pagination {
    }
    .ant-pagination-item-active {
      border-color: transparent !important;
    }
    .ant-pagination-item:hover {
      background-color: transparent !important;
    }
  `
  const hoverEffectStyle = `
    .hover-effect, .selected-hover {
      transition: all 0.4s ease;
    }
    .hover-effect:hover {
      opacity: 1 !important;
    }
    .selected-hover:hover {
      transform: scale(0.98);
    }
  `

  if (videoData.length === 0) {
    return (
      <Alert
        message='No hay contenidos'
        type='info'
        showIcon
        style={{
          margin: '40px 0',
          backgroundColor: 'rgba(237, 238, 242, 0.4)',
          border: 'none',
          borderRadius: '8px',
        }}
      />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
      }}
    >
      <style>{hoverEffectStyle}</style>
      <Row
        gutter={[4, 8]}
        style={{
          width: '100%',
        }}
      >
        {currentPageItems.map((videoData, index) => {
          const isSelected = selectedVideos.includes(videoData)
          return (
            <Col
              key={index}
              xs={12}
              style={{
                opacity: isSelected ? 1 : 0.6,
                display: 'flex',
                justifyContent: 'center',
              }}
              className={isSelected ? 'selected-hover' : 'hover-effect'}
            >
              <VideoCard
                videoData={videoData}
                size={{ width: 95, height: 135 }}
                hoverable={true}
                onClick={() => onVideoSelect(videoData)}
                boxShadow={isSelected}
                isSelected={isSelected}
              />
            </Col>
          )
        })}
      </Row>
      <style>{paginationStyle}</style>
      <Pagination
        current={currentPage}
        showSizeChanger={false}
        pageSize={pageSize}
        total={videoData.length}
        onChange={page => handlePageChange(page)}
        size='small'
        hideOnSinglePage
        align='center'
        style={{
          marginTop: '16px',
        }}
        itemRender={(page, type, originalElement) => {
          if (type === 'prev') {
            return (
              <div onClick={() => handlePageChange(currentPage - 1, 'prev')}>
                <LeftOutlined style={{ fontSize: '12px', color: 'rgba(23, 23, 23, 1)' }} />
              </div>
            )
          }
          if (type === 'next') {
            return (
              <div onClick={() => handlePageChange(currentPage + 1, 'next')}>
                <RightOutlined style={{ fontSize: '12px', color: 'rgba(23, 23, 23, 1)' }} />
              </div>
            )
          }
          if (type === 'page') {
            if (page < visibleRange.start || page > visibleRange.end) {
              return null
            }

            return (
              <div
                style={{
                  backgroundColor:
                    currentPage === page ? 'rgba(22, 24, 29, 1)' : 'rgba(237, 238, 242, 1)',
                  color: currentPage === page ? 'rgba(255, 255, 255, 1)' : 'rgba(23, 23, 23, 1)',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '12px',
                }}
              >
                {page}
              </div>
            )
          }
          return originalElement
        }}
      />
    </div>
  )
}
